
.feedback-bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 5% 0;
    height: 540px;
    width: 100vw;
    background-image: url('../images/old.jpg');
}

.feedback-bg-cabs{
  position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 5% 0;
    height: 540px;
    width: 100vw;
    background-image: url('../images/old.jpg');

}
.fixed-header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #000000;
    width: 100vw;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    box-shadow: 0px 5px 6.68px rgba(164, 160, 160, 0.12);
  }

  .header-logo{
    height: 50px;
    width: 50px;
  }



 .feedback-screen{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    padding: 5px;
    min-width: 35vw;
    width: 35vw;
  }
  
  body {
    font-family: 'Montserrat';
  }

  .top-container {
    text-align: center;
    color:#fff
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0px 5px 6.68px rgba(164, 160, 160, 0.12);
    padding: 0;
    border-radius: 20px;
    justify-content: space-evenly;
    width: 80%;
    height: auto;
    min-height: 550px;
  }

  .title{
    font-size: 20;
    font-weight: 400;
    font-family: 'Montserrat';
    color: #127B01;
  }
  
  .inner-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .element-image {
    height: 25px;
    width: 25px;
    margin: 10px 10px;
  }

  .element-text{
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 80%;
  }

  .title-header{
    font-size: 15px;
    font-weight: 700;
    color:#000;
    font-family: 'Montserrat';
  }

  .element-description {
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 400;
    margin-top: -3%;
  }

  .footer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  background-color: #ffffff;
  width: 80%;
  }

  .footer-image{
    height: 60px;
    width: 60px;
    margin: 20px;
  }

  .footer-text{
    font-family: 'Montserrat';
    font-size: 12px;
  }

  @media screen and (max-width: 1300px) {
    .feedback-screen{
      max-width: 60vh;
      width: 60vh;
  }
}

@media screen and (max-width: 527px) {
    .feedback-screen{
      max-width: 50vh;
      width: 50vh;
  }
}

@media screen and (max-width: 482px) {
    .feedback-screen{
      width: auto;
  }
}

@media screen and (max-width: 407px) {
    .feedback-screen{
      width: auto;

  }
}

@media screen and (max-width: 330px) {
    .feedback-screen{
      width: auto;

  }
}



  

 
  