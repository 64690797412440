.iconTop {
    position: absolute;
    display: flex;
    left: 50px;
    top: 40px;
  }
  
  .bg {
    height: 100%;
    align-items: center;
    padding: 30px;
  }
 
  .bottomContainerSuccess {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 5px 6.68px rgba(164, 160, 160, 0.12);
    border-radius: 20px;
    height: 70%;
    width: 70%;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  
  .input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: 1px;
    padding: 20px;
    border-color: #A6A0A0;
    font-size: 20px;
    font-family: 'Montserrat-Regular';
    margin: 20px;
  }
  
  .inputl {
    width: 700px;
    border-width: 1px;
    border-radius: 10px;
    height: 200px;
    border-color: #A6A0A0;
    font-size: 20px;
    font-family: 'Montserrat-Regular';
  }

  .inputlabel{
    margin: 2%;
    font-family: 'Montserrat';
    font-size: 15px;
    text-align: left;
  }

  textarea{
    height: 90px;
    width: 93%;
    padding-top:10px;
    margin: 10px;
    font-family: 'Montserrat';
    border: none;
  }

  .option{
   margin: 10px;
   height: 50px;
   width: 93%;
   font-family: 'Montserrat';
  }

  .slider{
    padding: 20px;
  }

  .ratingLabel{
    text-align: left;
    width: 100%;
    margin-bottom: 1%;
    font-size: 18px;
    font-family: 'Montserrat';
    padding: 20px;
  }

  .ratingLabelMark{
    text-align: center;
    margin-bottom: 1%;
    font-size: 30px;
    font-family: 'Montserrat-Regular';
  }
  
  .button {
    width: 100%;
    margin-top: 30px;
    background-color: #00763D;
    border: none;
    justify-content: center;
    color: #ffff;
    border-radius: 1px;
    height: 50px;
    cursor: pointer;
  }
  
  
  .goBack {
    position: fixed;
    right: 20px;
    bottom: 0px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #ccc;
    padding: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:20px;
  }
  
  .goBack:hover {
    background-color: #f2f2f2;
  }
