.containerComplaints{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-size: cover;
    background-image: url('../images/bg.jpg');
    background-size: cover;
    background-repeat: repeat;
}

.containerComplaintsFeedback{
  height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-size: cover;
    background-size: cover;
    background-repeat: repeat;

}

.iconTop {
    position: absolute;
    display: flex;
    left: 50px;
    top: 40px;
  }
  
  .text {
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
    color: #127B01;
  }
  
  .bg {
    height: 100%;
    align-items: center;
    padding: 30px;
  }
  
  .logo {
    width: 180px;
  }

  *:focus {
    outline: none; 
  }

  input[type="text"],[type="number"] {
    margin:10px;
    height: 50px;
    border: none;
    border-bottom: 1px solid #000;
    width: 90%;
    font-family: 'Montserrat';
    padding-left: 10px;
    background-color: transparent;
  }
  
  .inputl {
    width: 700px;
    border-width: 1px;
    border-radius: 10px;
    padding: 20px;
    height: 200px;
    border-color: #A6A0A0;
    font-size: 20px;
    margin: 20px;
    font-family: 'Montserrat-Regular';
  }

  .loader {
    width: 28px;
    height: 28px;
    border: 2px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
  
  .button {
    width: 57%;
    background-color: #00763D;
    justify-content: center;
    height: 10%;
    color: #ffff;
    border-radius: 1px;
  }
  
  .submitText {
    font-family: 'Montserrat';
    color: #fff;
    font-size: 15px;
  }
  
  .textbelow {
    font-size: 15px;
    font-family: 'Montserrat';
    padding-left: 10%;
    padding-right: 10%;
    color:#000;
  }
  